body {
    /* Fixed Body for fixed navigation bar at top */
    height: 100%;
    width: 100%;
    position: fixed;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Disable Text Selection */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.content {
    /* Content of app besides Navigation Bar */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    padding-bottom: 25px;
}